import { useEffect, useMemo, useState } from 'react';
import { strings } from '../utils/Localization/LocalizationStrings';
import SearchBar from '../components/SearchBar';
import SearchSideBar from '../components/SearchSideBar';

import { getWatchList } from '../modules/API/WatchAPI';
import WatchList from '../components/WatchList';
import { WatchFaceNFT } from '../types';
import {
  CategoryCheckBoxDefaultInputs,
  OsCheckBoxDefaultInputs,
  useWatchfaceFilter,
} from '../hooks/useWatchfaceFilter';
import watch from '../assets/img/Group 2517.svg';
import useLoading from '../customHooks/useLoading';

function Explore() {
  const [showSideBar, setShowSideBar] = useState(window.matchMedia('(min-width : 768px)').matches);

  const [watchFaces, setWatchFaces] = useState<WatchFaceNFT[] | null>(null);
  const { states, getSetter, filterWatchFace, sortWatchFace } = useWatchfaceFilter<WatchFaceNFT>();

  const [Loading, toggle] = useLoading();

  const filteredWatchFaces = useMemo(
    () => (watchFaces == null ? null : sortWatchFace(filterWatchFace(watchFaces))),
    [watchFaces, filterWatchFace, sortWatchFace]
  );

  useEffect(() => {
    (async () => {
      toggle(true);
      setWatchFaces(await getWatchList());
      toggle(false);
    })();
  }, []);

  return (
    <section className="w-full flex flex-col max-md:px-4 max-md:bg-white">
      <div className="flex flex-wrap md:gap-x-5 m-auto">
        <div className="w-full mt-4">
          <SearchBar
            showSideBarHandler={() => setShowSideBar((prev) => !prev)}
            placeholder={strings.InputSearch}
            setWord={getSetter('searchKeyword')}
            word={states.searchKeyword}
            selectedOption={states.sort}
            setSelectOption={getSetter('sort')}
          />
        </div>
        <div className="md:w-[224px] md:h-[764px] md:flex-[0_0_224px]">
          {showSideBar && (
            <SearchSideBar
              priceProps={{
                max: states.max,
                min: states.min,
                priceHandler: ({ min, max }) => {
                  getSetter('min')(min);
                  getSetter('max')(max);
                },
              }}
              categoryProps={{
                selectedCategories: states.selectedCategories,
                categoryList: CategoryCheckBoxDefaultInputs,
                categoryHandler: (checked, id) =>
                  getSetter('selectedCategories')((currentCategories) =>
                    checked
                      ? [...currentCategories, id]
                      : currentCategories.filter((el) => el !== id)
                  ),
              }}
              osProps={{
                selectedOs: states.selectedOs,
                osList: OsCheckBoxDefaultInputs,
                osHandler: (checked, id) =>
                  getSetter('selectedOs')((currentOs) =>
                    checked ? [...currentOs, id] : currentOs.filter((el) => el !== id)
                  ),
              }}
              closeOnFullscreen={() => setShowSideBar(false)}
            />
          )}
        </div>
        <div className="flex-[1_1_50%]">
          {filteredWatchFaces != null ? (
            <WatchList
              watchItems={filteredWatchFaces}
              linkFunction={(id) => `/${strings.getLanguage()}/purchase/${id}`}
            />
          ) : (
            <div className="flex flex-col w-full mt-[176px]">
              <img src={watch} alt="시계" className="m-auto " />
              <p className="m-auto text-bold">{strings.SearchLoading}</p>
            </div>
          )}
        </div>
      </div>
      <Loading />
    </section>
  );
}

export default Explore;
