/* eslint-disable camelcase */
import LocalizedStrings, { LocalizedStringsMethods } from 'react-localization';

interface TestInterface extends LocalizedStringsMethods {
  searchSidebar_PriceText: string;
  searchSidebar_Button: string;
  searchSidebar_CategoryText: string;
  searchSidebar_OsText: string;
  searchSidebar_CategoryList_analog: string;
  searchSidebar_CategoryList_digital: string;
  searchSidebar_CategoryList_digilog: string;
  searchSidebar_CategoryList_informative: string;
  searchSidebar_OsList_android: string;
  searchSidebar_OsList_ios: string;
  searchSidebar_FillterText: string;
  Header_Explore: string;
  Header_Create: string;
  Header_MyDesign: string;
  Header_MyApp: string;
  Header_WalletConnect: string;
  Header_SingIn: string;
  Header_TimeFlicText: string;
  Header_BackText: string;
  Header_AndroidDownload: string;
  Header_IosDownload: string;
  Facefacelist_Loading: string;
  Facefacelist_SelectWatch: string;
  Facefacelist_ShowInspectionCompleted: string;
  Signin_PasswordResetText: string;
  Signin_PasswordResetButton: string;
  Signin_SocialLoginText: string;
  Signin_SocialLogin: string;
  And: string;
  to: string;
  You_agree: string;
  Signin_IshaveEmailText: string;
  SignupButton: string;
  SigninButton: string;
  PasswordRreset_InputEmailTerms: string;
  PasswordRreset_PasswordRestText: string;
  PasswordRreset_CheckEmail: string;
  PasswordRreset_SendResetPwdLink: string;
  Signup_TitleText: string;
  Signup_NickNameTerm: string;
  Signup_NickNameTerm2: string;
  EmailTerm: string;
  Signup_EmailSendButton: string;
  Signup_VerifystatementdButton: string;
  Signup_PasswordTerm_En: string;
  Signup_PasswordTerm_Num: string;
  Signup_PasswordTerm_Symbol: string;
  Signup_PasswordTerm_safety: string;
  AgreeUseTermCheckbox: string;
  AgreePrivacyTermCheckbox: string;
  Singup_VerifyStatementTerms: string;
  Singup_PasswordTerms: string;
  ViewMoreButton: string;
  NextButton: string;
  Next: string;
  SignUpCelebrate_TitleText: string;
  SignUpCelebrate_FindStarting: string;
  SignUpCelebrate_ForNftWhatchFace: string;
  SignUpCelebrate_ConnectWallet: string;
  SignUpCelebrate_WalletConnectButton: string;
  SignUpCelebrate_HomeButton: string;
  ConnectWallet_TitleText: string;
  ConnectWallet_MetamaskText: string;
  ConnectWallet_WalletCopyText: string;
  ConnectWallet_LinkCopyButton: string;
  ConnectWallet_ConnectClearButton: string;
  ConnectWallet_HomeButton: string;
  ConnectWallet_ConnectButton: string;
  ConnectWallet_MetamaskError: string;
  ConnectWallet_ConnectError: string;
  ConnectWallet_UnknownError: string;
  ConnectWallet_WalletNotConnected: string;
  Footer_TimeFLIKPromotion: string;
  Footer_TimeFlicExplain: string;
  Footer_TimeFlicAppDownloadNow: string;
  Footer_Contact_CompanyTitleText: string;
  Footer_Contact_CompanyBusinessNumberTitle: string;
  Footer_Contact_CompanyBusinessNumber: string;
  Footer_Contact_CompanyReportNumberTitle: string;
  Footer_Contact_CompanyReportNumber: string;
  Footer_Contact_CompanyCEOTitle: string;
  Footer_Contact_CompanyCEOName: string;
  Footer_Contact_CompanyAddressTitle: string;
  Footer_Contact_CompanyAddress: string;
  Footer_Contact_CompanyTelTitle: string;
  Footer_Contact_CompanyTelNumber: string;
  Footer_Contact_CompanyFaxTitle: string;
  Footer_Contact_CompanyFaxNumber: string;
  Footer_CopyrightExplain: string;
  Terms_of_Use: string;
  Privacy_Policy: string;
  Contact_Us: string;
  App_Store: string;
  Google_Play: string;
  App_Download: string;
  Contact: string;
  Home_WatchfaceMarketplaceExplain: string;
  CREATE_ITEM: string;
  New_Arrivals: string;
  Trending: string;
  Home_MoreExplore: string;
  Home_About_TIMEFLICK_NFTs: string;
  Home_AllowTimeFlicNft: string;
  HomeBannerTitle_IsFirstWatchspace: string;
  HomeBannerSubTitle_FrequentlyQuestions: string;
  Go_To_FAQ: string;
  Create_Item: string;
  Cancel_Sale: string;
  Details: string;
  Item_Activity: string;
  Event: string;
  Price: string;
  From: string;
  To: string;
  Date: string;
  Owned_By: string;
  To_Cancel: string;
  To_Delete: string;
  To_Sel: string;
  Purchase: string;
  Show_In_App: string;
  AdjustToWatch: string;
  ShowApp: string;
  HiddenFace: string;
  RepotedHiddenFace: string;
  HowToCancelHidden: string;
  CancleHiddenEmail: string;
  Item: string;
  Reason: string;
  Blockchain: string;
  ETH: string;
  Royalty: string;
  Commission: string;
  Commission_value: string;
  Total_Price: string;
  Unlockable_Content: string;
  Page: string;
  Not_Found: string;
  Error404_Detial: string;
  Go_back: string;
  Go_Home: string;
  Search: string;
  WatchCount: string;
  TIMEFLIK_NFT_Marketplace: string;
  ConnectWallet: string;
  Marketplace: string;
  TMEFLK: string;
  Logout: string;
  Banner_Precautions: string;
  CommonBanner: string;
  Cancel: string;
  Remove: string;
  Apply: string;
  Image: string;
  Arrival_URL: string;
  Title_Text: string;
  Sub_Text: string;
  Button_Text: string;
  Minting_Loading: string;
  MultiBanner: string;
  ReportedNoSearch: string;
  Thums: string;
  Reported: string;
  Reporter: string;
  ReportDate: string;
  ReportDetailReason: string;
  ReportAction: string;
  ReportActionDate: string;
  Report_Content: string;
  Report_Reson: string;
  ReportUnableCancel: string;
  Report_Slander: string;
  Report_Pornography: string;
  Report_Abuse: string;
  Report_DetailReasonTerms: string;
  Report_Received: string;
  Report_Etc: string;
  Report_Completed: string;
  Reminder_Time: string;
  AllRead: string;
  AllKeep: string;
  KeepCancel: string;
  KeepAlarm: string;
  CopyItemLinkModal_Title: string;
  ConfirmDeleteMdal_Title: string;
  LogoutModal_Title: string;
  RemoveContentModal_Title: string;
  ApplyContentModal_Title: string;
  AdminLoginFailModal_Title: string;
  CancellSaleModal_Title: string;
  CreateWatchModal_Title: string;
  LanguageCodeInput: string;
  WritingList: string;
  CurrentRealServerList: string;
  ListBeingEdited: string;
  ServiceApply: string;
  ServiceReservation: string;
  TopMultiBanner: string;
  MiddleMultiBanner: string;
  BottomMultiBanner: string;
  Banner_NoTitle: string;
  InputSearch: string;
  SearchClearButtonIncon: string;
  YearMonthDayUnit: string;
  AdminReport_NoSearchIcon: string;
  MetamaskWalletIcon: string;
  CheckIcon: string;
  WalletIcon: string;
  GoBackIcon: string;
  MenuOpenIcon: string;
  MenuCloseIcon: string;
  NotificationIcon: string;
  ArrowIcon: string;
  NotificationedNotificationIcon: string;
  CloseButton: string;
  Locker: string;
  Inbox: string;
  Newest: string;
  PriceHigh: string;
  PriceLowest: string;
  AdminReport_Select: string;
  AdminReport_HiddenKeep: string;
  AdminReport_HiddenClear: string;
  SearchLoading: string;
  SearchNoRsCreate: string;
  Purchase_Loading: string;
  PurchaseProgress: string;
  PurchaseComplete: string;
  PurchaseFailed: string;
  SellFailed: string;
  Confirm: string;
  SignUpCelebrate_FirecrackerIcon: string;
  Detail_Loading: string;
  Enter_Email: string;
  Enter_Password: string;
  Enter_NickName: string;
  Enter_VerifyStatement: string;
  Enter_Code: string;
  EmailResetTerms: string;
  Check_Email: string;
  Check_Code: string;
  Signup_CheckCertification: string;
  Go_Back: string;
  Signup_Agreeeterms: string;
  Signup_Verificationcompleted: string;
  Signup_CheckVerification: string;
  Signup_CheckPassword: string;
  Signup_CheckDuplicateEmail: string;
  Signup_InvalidNickname: string;
  Signup_Signup: string;
  Signin_Signin: string;
  UserPage_Loading: string;
  Explore_Loading: string;
  Explore_NoSearch: string;
  Explore_NoSearchResult: string;
  AgreeTermsModal_TitleText: string;
  AgreeTermsModal_TitleDetailText: string;
  AgreeTermsModal_AllAgree: string;
  AgreeTermsModal_ButtonText: string;
  AgreeTermsModal_AgreeUseTerm: string;
  AgreeTermsModal_AgreePrivacyTerm: string;
  SignUpCelebrate_Greetings: string;
  Signup_EmailTerm2: string;
  Signup_NickNameFocusTerms: string;
  Royalty_Terms: string;
  Singup_NickNameTerms: string;
  HiddenItem: string;
  ReportReason: string;
  ContactUnhide: string;
  HiddenContent: string;
  usingTestnet: string;
  WorkInProcess: string;
  networkError: string;
  mintingError: string;
  chooseDate: string;
  reservationTime: string;
  reservationList: string;
  proceedPurchase: string;
  signingTimeout: string;
  approvalAll: string;
  mintNFT: string;
  proceedSell: string;
  userCancel: string;
  emailSent: string;
  emailSentFail: string;
}
export const strings: TestInterface = new LocalizedStrings({
  kr: {
    searchSidebar_PriceText: 'Price',
    searchSidebar_Button: 'Apply',
    searchSidebar_CategoryText: 'Category',
    searchSidebar_OsText: 'OS',
    searchSidebar_CategoryList_analog: 'analog',
    searchSidebar_CategoryList_digital: 'Digital',
    searchSidebar_CategoryList_digilog: 'Digilog',
    searchSidebar_CategoryList_informative: 'Informative',
    searchSidebar_OsList_android: 'Android',
    searchSidebar_OsList_ios: 'IOS',
    searchSidebar_FillterText: '필터',
    Header_Explore: 'Explore',
    Header_Create: 'Create',
    Header_MyDesign: 'Design your own',
    Header_MyApp: 'Download Our App',
    Header_WalletConnect: '월렛 연결하기',
    Header_SingIn: 'Sign In',
    Header_TimeFlicText: '타임플릭앱',
    Header_BackText: '으로 돌아가기',
    Header_AndroidDownload: '안드로이드 다운로드',
    Header_IosDownload: 'ios 다운로드',
    Facefacelist_Loading: '생성 가능 워치페이스 목록을 불러오고 있습니다...',
    Facefacelist_SelectWatch: ' 아이템으로 생성할 워치페이스를 선택하세요.',
    Facefacelist_ShowInspectionCompleted: ' 플릭스튜디오에서 검수 완료된 페이스만 볼수 있습니다.',
    Signin_PasswordResetText: '비밀번호를 잊으셨나요?',
    Signin_PasswordResetButton: '비밀번호 재설정',
    Signin_SocialLoginText: '소셜 계정으로 로그인',
    Signin_SocialLogin: '소셜 로그인 시 ',
    And: '및 ',
    to: '에',
    You_agree: '동의하신 것으로 간주합니다',
    Signin_IshaveEmailText: '아직 계정이 없으신가요?',
    SignupButton: '회원가입',
    SigninButton: '로그인',
    PasswordRreset_InputEmailTerms: '가입시 기재한 이메일을 입력해주세요.',
    PasswordRreset_PasswordRestText: '비밀번호 재설정',
    PasswordRreset_CheckEmail: '메일을 확인해주세요',
    PasswordRreset_SendResetPwdLink: '비밀번호 재설정 링크를 보냈어요.',
    Signup_TitleText: '회원가입',
    Signup_NickNameTerm: '*닉네임(최소2글자)',
    Signup_NickNameTerm2: '20bytes 이내로 작성해주세요.',
    EmailTerm: ' *이메일',
    Signup_EmailTerm2: ' 인증을 위해 정확히 입력해주세요',
    Signup_EmailSendButton: '발송',
    Signup_VerifystatementdButton: '인증',
    Signup_PasswordTerm_En: '영문',
    Signup_PasswordTerm_Num: '숫자',
    Signup_PasswordTerm_Symbol: '특수기호',
    Signup_PasswordTerm_safety: '안전도',
    AgreeUseTermCheckbox: '이용약관 동의 (필수)',
    AgreePrivacyTermCheckbox: '개인정보처리방침 동의 (필수)',
    Singup_NickNameTerms: '*닉네임(최소 2글자)',
    Signup_NickNameFocusTerms: '영문, 한글, 숫자 사용 가능',
    Singup_VerifyStatementTerms: '*인증 문구',
    Singup_PasswordTerms: '*비밀번호',
    ViewMoreButton: '더보기',
    NextButton: '다음',
    Next: '계속',
    SignUpCelebrate_TitleText: '가입 완료',
    SignUpCelebrate_Greetings: '안녕! 만나서 반가워요',
    SignUpCelebrate_FindStarting: '이제 나만의 페이스를 찾아볼까요?',
    SignUpCelebrate_ForNftWhatchFace: 'NFT 워치 페이스 구매를 위해서는',
    SignUpCelebrate_ConnectWallet: '월렛을 연결해야 해요.',
    SignUpCelebrate_WalletConnectButton: '월렛 연결하기',
    SignUpCelebrate_HomeButton: '홈으로 이동하기',
    ConnectWallet_TitleText: '월렛 연결',
    ConnectWallet_MetamaskText: 'Metamask',
    ConnectWallet_WalletCopyText: '월렛 주소를 복사하였습니다',
    ConnectWallet_LinkCopyButton: '링크 복사',
    ConnectWallet_ConnectClearButton: '연결 해제',
    ConnectWallet_HomeButton: '홈으로 이동하기',
    ConnectWallet_ConnectButton: '연결',
    ConnectWallet_MetamaskError: '메타마스크 설치가 필요합니다.',
    ConnectWallet_ConnectError: '월렛이 연결되지 않았습니다.',
    ConnectWallet_UnknownError: '월렛 연결에 실패했습니다. 다시 시도해주세요.',
    Footer_TimeFLIKPromotion:
      '전세계의 사용자들이 만드는 수백만개의 워치페이스 부터, 다양한 디자인과 \n높은 품질의 스트랩 악세서리 까지. \n지금, 세상에 하나밖에 없는 나만의 시계를 전세계 사용자들과 공유해보세요.',
    Footer_TimeFlicExplain: '*TIMEFLIK(TM)은 지난 10년간 걸어왔던 MR TIME의 새로운 이름입니다.',
    Footer_TimeFlicAppDownloadNow:
      '수많은 혜택이 기다리고 있는 타임플릭앱을 지금 \n 다운로드 받아보세요!',
    Footer_Contact_CompanyTitleText: '주식회사 앱포스터',
    Footer_Contact_CompanyBusinessNumberTitle: '사업자 등록번호',
    Footer_Contact_CompanyBusinessNumber: '107-87-39753',
    Footer_Contact_CompanyReportNumberTitle: '통신판매업신고번호',
    Footer_Contact_CompanyReportNumber: '제 2013-서울마포-1337호',
    Footer_Contact_CompanyCEOTitle: '대표이사',
    Footer_Contact_CompanyCEOName: '경성현',
    Footer_Contact_CompanyAddressTitle: '주소',
    Footer_Contact_CompanyAddress: '서울특별시 마포구 성암로 189, DMC 타워 7층',
    Footer_Contact_CompanyTelTitle: '대표전화',
    Footer_Contact_CompanyTelNumber: '1666 - 9015',
    Footer_Contact_CompanyFaxTitle: '팩스',
    Footer_Contact_CompanyFaxNumber: ' 02-538-0344',
    Footer_CopyrightExplain: 'Copyright 2023 주식회사 앱포스터. All Rights Reserved.',
    Terms_of_Use: '이용약관',
    Privacy_Policy: '개인정보처리방침',
    Contact_Us: 'Contact Us',
    App_Store: 'App Store',
    Google_Play: 'Google Play',
    App_Download: 'App Download',
    Contact: 'Contact',
    Home_WatchfaceMarketplaceExplain:
      'Largest Watchface Marketplace To Collect, Buy and Sell Creative NFTs Digital Assets',
    CREATE_ITEM: 'CREATE ITEM',
    New_Arrivals: 'New Arrivals',
    Trending: 'Trending',
    Home_MoreExplore: 'Explore More NFT Watch Faces',
    Home_About_TIMEFLICK_NFTs: 'About TIMEFLICK NFTs',
    Home_AllowTimeFlicNft:
      'TIMEFLIK NFT Watch Faces allow you Blahblahblah supercalifrigerlisticaspialidocious \n  Blahblahblah supercalifrigerlisticaspialidocious',
    HomeBannerTitle_IsFirstWatchspace: 'NFT 워치스페이스,처음이시죠?',
    HomeBannerSubTitle_FrequentlyQuestions: '자주 묻는 질문(FAQ)',
    Go_To_FAQ: ' FAQ 바로가기',
    Create_Item: '아이템 생성하기',
    Cancel_Sale: '판매 취소',
    Details: 'Details',
    Item_Activity: 'Item Activity',
    Event: 'Event',
    Price: 'Price',
    From: 'From',
    To: 'To',
    Date: 'Date',
    Owned_By: 'Owned By',
    To_Cancel: '판매 취소',
    To_Delete: '삭제하기',
    To_Sel: '판매하기',
    Purchase: '구매하기',
    ShowApp: '앱에서 보기',
    HiddenFace: '숨김 처리된 컨텐츠 입니다',
    RepotedHiddenFace: '신고 처리되어 숨겨진 페이스입니다.',
    HowToCancelHidden: '숨김 해제를 위해서는 고객센터로 문의해주세요.',
    CancleHiddenEmail: 'help@apposter.com',
    Item: 'Item',
    Reason: '사유',
    Blockchain: 'Blockchain',
    ETH: 'ETH',
    Royalty: 'Royalty',
    Royalty_Terms: '10% 범위 내에서 소수점 둘째자리까지 입력 가능합니다.',
    Commission: 'Commission',
    Commission_value: '2.5%',
    Total_Price: 'Total Price',
    Unlockable_Content: 'Unlockable Content',
    Page: 'Page',
    Not_Found: ' Not Found',
    Error404_Detial: '요청하신 페이지를 찾을 수 없습니다',
    Go_back: '이전 페이지로 이동',
    Go_Home: '홈으로 이동하기',
    Search: '검색',
    WatchCount: 'watch faces(s)',
    TIMEFLIK_NFT_Marketplace: 'TIMEFLIK NFT Marketplace',
    ConnectWallet: ' 관리자 월렛 연결하기',
    Marketplace: 'Marketplace',
    TMEFLK: 'T/MEFL/K',
    Logout: 'Logout',
    Banner_Precautions: '* 한 항목을 완전히 저장하신 후,\n 새로운 항목을 만드세요',
    CommonBanner: '일반배너',
    Cancel: '취소',
    Remove: '삭제',
    Apply: '적용',
    Image: '이미지',
    Arrival_URL: '도착 URL',
    Title_Text: '제목 텍스트',
    Sub_Text: '소제목 텍스트',
    Button_Text: '버튼 텍스트',
    Minting_Loading: '민팅 작업 진행중 입니다...',
    MultiBanner: '멀티배너',
    ReportedNoSearch: '등록된 신고 내역이 없습니다.',
    Thums: '썸네일',
    Reported: '신고 대상자',
    Reporter: '신고자',
    ReportDate: '신고 일시',
    ReportDetailReason: '상세 사유',
    ReportAction: '조치',
    ReportActionDate: '조치 일시',
    Report_Content: '컨텐츠 신고',
    Report_Reson: '신고 사유',
    ReportUnableCancel: '신고 시 신고 취소 불가합니다.',
    Report_Slander: '특정인 비방',
    Report_Pornography: '음란물',
    Report_Abuse: '욕설',
    Report_DetailReasonTerms: '신고 상세 사유를 기재해주세요.',
    Report_Received: '신고가 접수되었습니다.',
    Report_Etc: '기타',
    Report_Completed: '신고완료',
    Reminder_Time: '알림 시간',
    AllRead: '모두 읽기',
    AllKeep: '모두 보관',
    KeepCancel: '보관 취소',
    KeepAlarm: '알람 보관',
    CopyItemLinkModal_Title: '아이템의 링크가\n복사되었습니다',
    ConfirmDeleteMdal_Title: '삭제된 페이스는 복구되지 않습니다.\n 삭제를 진행하시겠습니까?',
    LogoutModal_Title: '로그아웃이 되었습니다',
    RemoveContentModal_Title: '콘텐츠가 삭제되었습니다',
    ApplyContentModal_Title: '콘텐츠가 적용되었습니다',
    AdminLoginFailModal_Title: '관리자 권한이 없습니다',
    CancellSaleModal_Title: '판매 취소 하시겠습니까?',
    CreateWatchModal_Title: '아이템 생성 완료!\n 마이페이지에서 확인 후 판매도 시도해보세요',
    LanguageCodeInput: '언어코드 입력',
    WritingList: '편집중인 목록',
    CurrentRealServerList: '현재 실서버 목록',
    ListBeingEdited: '편집 중인 목록',
    ServiceApply: '서비스 적용',
    ServiceReservation: '서비스 예약',
    TopMultiBanner: '상단 멀티 배너',
    MiddleMultiBanner: '중단 띠배너',
    BottomMultiBanner: '하단 띠배너',
    Banner_NoTitle: '제목 없음',
    InputSearch: '검색어를 입력해주세요',
    SearchClearButtonIncon: '검색어 삭제 버튼 아이콘 이미지',
    YearMonthDayUnit: '년.월.일',
    AdminReport_NoSearchIcon: '신고내역 검색 아이콘',
    MetamaskWalletIcon: '메타마스크 지갑 아이콘',
    CheckIcon: '체크 아이콘',
    WalletIcon: '지갑 아이콘',
    GoBackIcon: '뒤로가기 아이콘',
    MenuOpenIcon: '메뉴 열기 아이콘',
    MenuCloseIcon: '메뉴 닫기 아이콘',
    NotificationIcon: '알람 아이콘',
    ArrowIcon: '셀렉트 박스 화살표',
    NotificationedNotificationIcon: '알람알림 아이톤',
    CloseButton: '닫기 버튼',
    Locker: '보관함',
    Inbox: '수신함',
    Newest: '최신순',
    PriceHigh: '가격높은순',
    PriceLowest: '가격낮은순',
    AdminReport_Select: '선택',
    AdminReport_HiddenKeep: '숨김 유지',
    AdminReport_HiddenClear: '숨김 해제',
    SearchLoading: '워치페이스를 조회중입니다.',
    SearchNoRsCreate: '생성 가능한 워치페이스가 없습니다.',
    Purchase_Loading: '구매 작업 진행중 입니다...',
    PurchaseProgress: '구매가 진행중입니다.\n잠시만 기다려주세요',
    PurchaseComplete: '구매가 완료되었습니다.',
    PurchaseFailed: '구매에 실패하였습니다.\n다시 시도해주세요.',
    SellFailed: '판매에 실패하였습니다.\n다시 시도해주세요.',
    Confirm: '확인',
    SignUpCelebrate_FirecrackerIcon: '웰컴 폭죽 이미지',
    Detail_Loading: '상세정보를 불러오고 있습니다...',
    Enter_Email: '이메일을 입력해주세요',
    Enter_Password: '비밀번호를 입력해주세요',
    Enter_NickName: '닉네임을 입력해주세요',
    Enter_VerifyStatement: '인증문구를 입력해주세요',
    Enter_Code: '코드를 입력해주세요',
    EmailResetTerms: '이메일 변경시 재인증이 필요합니다.',
    Check_Email: '해당 이메일이 존재하지 않습니다.',
    Check_Code: '코드가 일치하지 않습니다.',
    Signup_CheckCertification: '인증을 하지 않으셨습니다.',
    Go_Back: '뒤로',
    Signup_Agreeeterms: '약관에 동의하지 않으셨습니다.',
    Signup_Verificationcompleted: '인증완료',
    Signup_CheckVerification: '인증문구를 다시 한번 확인해주세요',
    Signup_CheckPassword: '패스워드가 일치하지 않습니다.',
    Signup_CheckDuplicateEmail: '중복된 이메일입니다.',
    Signup_InvalidNickname: '사용할 수 없는 닉네임입니다.',
    Signup_Signup: '회원가입에 실패하셨습니다 다시 시도 해주세요',
    Signin_Signin: '로그인에 실패하셨습니다 다시 시도 해주세요',
    UserPage_Loading: '사용자 페이지 정보를 불러오고 있습니다...',
    Explore_Loading: '워치페이스 목록을 불러오고 있습니다...',
    Explore_NoSearch: '찾으시는 워치페이스 정보를 검색해주세요.',
    Explore_NoSearchResult: '검색 정보와 일치하는 워치페이스가 없습니다.',
    AgreeTermsModal_TitleText: '이용약관 동의',
    AgreeTermsModal_TitleDetailText: '이용약관 및 개인정보처리방침 동의',
    AgreeTermsModal_AllAgree: '전체 동의',
    AgreeTermsModal_ButtonText: '가입 완료',
    AgreeTermsModal_AgreeUseTerm:
      '타임플릭(TIMEFLIK) 이용약관\n\n제1조(목적)\n\n이 약관은 (주)앱포스터(이하 ‘회사’라 합니다)가 제공하는 모든 서비스와 이와 연계된 모바일 애플리케이션 서비스("타임플',
    AgreeTermsModal_AgreePrivacyTerm:
      '개인정보처리방침\n\n주식회사 앱포스터(이하 “회사”)는 이용자의 개인정보를 보호하고, 이와 관련된 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립하고 공개합니다. ',
    HiddenItem: '신고 처리되어 숨겨진 페이스입니다.',
    ReportReason: '사유',
    ContactUnhide: '숨김 해제를 위해서는 고객센터로 문의해주세요.',
    HiddenContent: '숨김 처리된 컨텐츠 입니다',
    Show_In_App: '앱에서 보기',
    AdjustToWatch: '워치에 적용하기',
    usingTestnet: '테스트넷 사용중입니다.',
    WorkInProcess: '작업 진행중 입니다...\n잠시만 기다려 주세요',
    ConnectWallet_WalletNotConnected: '지갑이 연결되지 않았습니다. 먼저 지갑을 연결해주세요.',
    networkError: '네트워크 오류가 발생했습니다. 잠시 후 다시 시도해주세요.',
    mintingError: '파일을 업로드하는 중에 문제가 발생했습니다.',
    chooseDate: 'Choose a date',
    reservationTime: '예약 시간 HH:mm',
    reservationList: '예약 리스트',
    proceedPurchase: '구매를 진행합니다.',
    signingTimeout: '서명 시간이 초과되었습니다. 다시 시도해주세요.',
    approvalAll:
      'nft 토큰에 대한 액세스 권한이 필요합니다.\n 판매를 계속하려면 모든 권한을 승인해주세요.',
    mintNFT: 'NFT 토큰을 생성합니다.',
    proceedSell: '판매를 진행합니다.',
    userCancel: '취소하셨습니다.',
    emailSent: '이메일이 발송되었습니다.',
    emailSentFail: '이메일 발송에 실패했습니다.',
  },
  en: {
    searchSidebar_PriceText: 'Price',
    searchSidebar_Button: 'Apply',
    searchSidebar_CategoryText: 'Category',
    searchSidebar_OsText: 'OS',
    searchSidebar_CategoryList_analog: 'analog',
    searchSidebar_CategoryList_digital: 'Digital',
    searchSidebar_CategoryList_digilog: 'Digilog',
    searchSidebar_CategoryList_informative: 'Informative',
    searchSidebar_OsList_android: 'Android',
    searchSidebar_OsList_ios: 'IOS',
    searchSidebar_FillterText: 'Filter',
    Header_Explore: 'Explore',
    Header_Create: 'Create',
    Header_MyDesign: 'Design your own',
    Header_MyApp: 'Download Our App',
    Header_WalletConnect: 'Connect wallet',
    Header_SingIn: 'Sign In',
    Header_TimeFlicText: '타임플릭앱',
    Header_BackText: '으로 돌아가기',
    Header_AndroidDownload: '안드로이드 다운로드',
    Header_IosDownload: 'ios 다운로드',
    Facefacelist_Loading: '생성 가능 워치페이스 목록을 불러오고 있습니다...',
    Facefacelist_SelectWatch: ' 아이템으로 생성할 워치페이스를 선택하세요.',
    Facefacelist_ShowInspectionCompleted: ' 플릭스튜디오에서 검수 완료된 페이스만 볼수 있습니다.',
    Signin_PasswordResetText: '비밀번호를 잊으셨나요?',
    Signin_PasswordResetButton: '비밀번호 재설정',
    Signin_SocialLoginText: '소셜 계정으로 로그인',
    Signin_SocialLogin: '소셜 로그인 시 ',
    And: '및 ',
    to: '에',
    You_agree: '동의하신 것으로 간주합니다',
    Signin_IshaveEmailText: '아직 계정이 없으신가요?',
    SignupButton: '회원가입',
    SigninButton: '로그인',
    PasswordRreset_InputEmailTerms: '가입시 기재한 이메일을 입력해주세요.',
    PasswordRreset_PasswordRestText: '비밀번호 재설정',
    PasswordRreset_CheckEmail: '메일을 확인해주세요',
    PasswordRreset_SendResetPwdLink: '비밀번호 재설정 링크를 보냈어요.',
    Signup_TitleText: '회원가입',
    Signup_NickNameTerm: '*닉네임(최소2글자)',
    Signup_NickNameTerm2: '20bytes 이내로 작성해주세요.',
    EmailTerm: ' *이메일',
    Signup_EmailTerm2: ' 인증을 위해 정확히 입력해주세요',
    Signup_EmailSendButton: '발송',
    Signup_VerifystatementdButton: '인증',
    Signup_PasswordTerm_En: '영문',
    Signup_PasswordTerm_Num: '숫자',
    Signup_PasswordTerm_Symbol: '특수기호',
    Signup_PasswordTerm_safety: '안전도',
    AgreeUseTermCheckbox: '이용약관 동의 (필수)',
    AgreePrivacyTermCheckbox: '개인정보처리방침 동의 (필수)',
    Singup_NickNameTerms: '*닉네임(최소 2글자)',
    Signup_NickNameFocusTerms: '영문, 한글, 숫자 사용 가능',
    Singup_VerifyStatementTerms: '*인증 문구',
    Singup_PasswordTerms: '*비밀번호',
    ViewMoreButton: '더보기',
    NextButton: '다음',
    Next: '계속',
    SignUpCelebrate_TitleText: '가입 완료',
    SignUpCelebrate_Greetings: '안녕! 만나서 반가워요',
    SignUpCelebrate_FindStarting: '이제 나만의 페이스를 찾아볼까요?',
    SignUpCelebrate_ForNftWhatchFace: 'NFT 워치 페이스 구매를 위해서는',
    SignUpCelebrate_ConnectWallet: '월렛을 연결해야 해요.',
    SignUpCelebrate_WalletConnectButton: '월렛 연결하기',
    SignUpCelebrate_HomeButton: '홈으로 이동하기',
    ConnectWallet_TitleText: '월렛 연결',
    ConnectWallet_MetamaskText: 'Metamask',
    ConnectWallet_WalletCopyText: '월렛 주소를 복사하였습니다',
    ConnectWallet_LinkCopyButton: '링크 복사',
    ConnectWallet_ConnectClearButton: '연결 해제',
    ConnectWallet_HomeButton: '홈으로 이동하기',
    ConnectWallet_ConnectButton: '연결',
    ConnectWallet_MetamaskError: '메타마스크 설치가 필요합니다.',
    ConnectWallet_ConnectError: '월렛이 연결되지 않았습니다.',
    ConnectWallet_UnknownError: '월렛 연결에 실패했습니다. 다시 시도해주세요.',
    Footer_TimeFLIKPromotion:
      '전세계의 사용자들이 만드는 수백만개의 워치페이스 부터, 다양한 디자인과 \n높은 품질의 스트랩 악세서리 까지. \n지금, 세상에 하나밖에 없는 나만의 시계를 전세계 사용자들과 공유해보세요.',
    Footer_TimeFlicExplain: '*TIMEFLIK(TM)은 지난 10년간 걸어왔던 MR TIME의 새로운 이름입니다.',
    Footer_TimeFlicAppDownloadNow:
      '수많은 혜택이 기다리고 있는 타임플릭앱을 지금 \n 다운로드 받아보세요!',
    Footer_Contact_CompanyTitleText: '주식회사 앱포스터',
    Footer_Contact_CompanyBusinessNumberTitle: '사업자 등록번호',
    Footer_Contact_CompanyBusinessNumber: '107-87-39753',
    Footer_Contact_CompanyReportNumberTitle: '통신판매업신고번호',
    Footer_Contact_CompanyReportNumber: '제 2013-서울마포-1337호',
    Footer_Contact_CompanyCEOTitle: '대표이사',
    Footer_Contact_CompanyCEOName: '경성현',
    Footer_Contact_CompanyAddressTitle: '주소',
    Footer_Contact_CompanyAddress: '서울특별시 마포구 성암로 189, DMC 타워 7층',
    Footer_Contact_CompanyTelTitle: '대표전화',
    Footer_Contact_CompanyTelNumber: '1666 - 9015',
    Footer_Contact_CompanyFaxTitle: '팩스',
    Footer_Contact_CompanyFaxNumber: ' 02-538-0344',
    Footer_CopyrightExplain: 'Copyright 2023 주식회사 앱포스터. All Rights Reserved.',
    Terms_of_Use: '이용약관',
    Privacy_Policy: '개인정보처리방침',
    Contact_Us: 'Contact Us',
    App_Store: 'App Store',
    Google_Play: 'Google Play',
    App_Download: 'App Download',
    Contact: 'Contact',
    Home_WatchfaceMarketplaceExplain:
      'Largest Watchface Marketplace To Collect, Buy and Sell Creative NFTs Digital Assets',
    CREATE_ITEM: 'CREATE ITEM',
    New_Arrivals: 'New Arrivals',
    Trending: 'Trending',
    Home_MoreExplore: 'Explore More NFT Watch Faces',
    Home_About_TIMEFLICK_NFTs: 'About TIMEFLICK NFTs',
    Home_AllowTimeFlicNft:
      'TIMEFLIK NFT Watch Faces allow you Blahblahblah supercalifrigerlisticaspialidocious \n  Blahblahblah supercalifrigerlisticaspialidocious',
    HomeBannerTitle_IsFirstWatchspace: 'NFT 워치스페이스,처음이시죠?',
    HomeBannerSubTitle_FrequentlyQuestions: '자주 묻는 질문(FAQ)',
    Go_To_FAQ: ' FAQ 바로가기',
    Create_Item: '아이템 생성하기',
    Cancel_Sale: '판매 취소',
    Details: 'Details',
    Item_Activity: 'Item Activity',
    Event: 'Event',
    Price: 'Price',
    From: 'From',
    To: 'To',
    Date: 'Date',
    Owned_By: 'Owned By',
    To_Cancel: '판매 취소',
    To_Delete: '삭제하기',
    To_Sel: '판매하기',
    Purchase: '구매하기',
    ShowApp: '앱에서 보기',
    HiddenFace: '숨김 처리된 컨텐츠 입니다',
    RepotedHiddenFace: '신고 처리되어 숨겨진 페이스입니다.',
    HowToCancelHidden: '숨김 해제를 위해서는 고객센터로 문의해주세요.',
    CancleHiddenEmail: 'help@apposter.com',
    Item: 'Item',
    Reason: '사유',
    Blockchain: 'Blockchain',
    ETH: 'ETH',
    Royalty: 'Royalty',
    Royalty_Terms: '10% 범위 내에서 소수점 둘째자리까지 입력 가능합니다.',
    Commission: 'Commission',
    Commission_value: '2.5%',
    Total_Price: 'Total Price',
    Unlockable_Content: 'Unlockable Content',
    Page: 'Page',
    Not_Found: ' Not Found',
    Error404_Detial: '요청하신 페이지를 찾을 수 없습니다',
    Go_back: '이전 페이지로 이동',
    Go_Home: '홈으로 이동하기',
    Search: '검색',
    WatchCount: 'watch faces(s)',
    TIMEFLIK_NFT_Marketplace: 'TIMEFLIK NFT Marketplace',
    ConnectWallet: ' 관리자 월렛 연결하기',
    Marketplace: 'Marketplace',
    TMEFLK: 'T/MEFL/K',
    Logout: 'Logout',
    Banner_Precautions: '* 한 항목을 완전히 저장하신 후,\n 새로운 항목을 만드세요',
    CommonBanner: '일반배너',
    Cancel: '취소',
    Remove: '삭제',
    Apply: '적용',
    Image: '이미지',
    Arrival_URL: '도착 URL',
    Title_Text: '제목 텍스트',
    Sub_Text: '소제목 텍스트',
    Button_Text: '버튼 텍스트',
    Minting_Loading: '민팅 작업 진행중 입니다...',
    MultiBanner: '멀티배너',
    ReportedNoSearch: '등록된 신고 내역이 없습니다.',
    Thums: '썸네일',
    Reported: '신고 대상자',
    Reporter: '신고자',
    ReportDate: '신고 일시',
    ReportDetailReason: '상세 사유',
    ReportAction: '조치',
    ReportActionDate: '조치 일시',
    Report_Content: '컨텐츠 신고',
    Report_Reson: '신고 사유',
    ReportUnableCancel: '신고 시 신고 취소 불가합니다.',
    Report_Slander: '특정인 비방',
    Report_Pornography: '음란물',
    Report_Abuse: '욕설',
    Report_DetailReasonTerms: '신고 상세 사유를 기재해주세요.',
    Report_Received: '신고가 접수되었습니다.',
    Report_Etc: '기타',
    Report_Completed: '신고완료',
    Reminder_Time: '알림 시간',
    AllRead: '모두 읽기',
    AllKeep: '모두 보관',
    KeepCancel: '보관 취소',
    KeepAlarm: '알람 보관',
    CopyItemLinkModal_Title: '아이템의 링크가\n복사되었습니다',
    ConfirmDeleteMdal_Title: '삭제된 페이스는 복구되지 않습니다.\n 삭제를 진행하시겠습니까?',
    LogoutModal_Title: '로그아웃이 되었습니다',
    RemoveContentModal_Title: '콘텐츠가 삭제되었습니다',
    ApplyContentModal_Title: '콘텐츠가 적용되었습니다',
    AdminLoginFailModal_Title: '관리자 권한이 없습니다',
    CancellSaleModal_Title: '판매 취소 하시겠습니까?',
    CreateWatchModal_Title: '아이템 생성 완료!\n 마이페이지에서 확인 후 판매도 시도해보세요',
    LanguageCodeInput: '언어코드 입력',
    WritingList: '편집중인 목록',
    CurrentRealServerList: '현재 실서버 목록',
    ListBeingEdited: '편집 중인 목록',
    ServiceApply: '서비스 적용',
    ServiceReservation: '서비스 예약',
    TopMultiBanner: '상단 멀티 배너',
    MiddleMultiBanner: '중단 띠배너',
    BottomMultiBanner: '하단 띠배너',
    Banner_NoTitle: '제목 없음',
    InputSearch: '검색어를 입력해주세요',
    SearchClearButtonIncon: '검색어 삭제 버튼 아이콘 이미지',
    YearMonthDayUnit: '년.월.일',
    AdminReport_NoSearchIcon: '신고내역 검색 아이콘',
    MetamaskWalletIcon: '메타마스크 지갑 아이콘',
    CheckIcon: '체크 아이콘',
    WalletIcon: '지갑 아이콘',
    GoBackIcon: '뒤로가기 아이콘',
    MenuOpenIcon: '메뉴 열기 아이콘',
    MenuCloseIcon: '메뉴 닫기 아이콘',
    NotificationIcon: '알람 아이콘',
    ArrowIcon: '셀렉트 박스 화살표',
    NotificationedNotificationIcon: '알람알림 아이톤',
    CloseButton: '닫기 버튼',
    Locker: '보관함',
    Inbox: '수신함',
    Newest: '최신순',
    PriceHigh: '가격높은순',
    PriceLowest: '가격낮은순',
    AdminReport_Select: '선택',
    AdminReport_HiddenKeep: '숨김 유지',
    AdminReport_HiddenClear: '숨김 해제',
    SearchLoading: '워치페이스를 조회중입니다.',
    SearchNoRsCreate: '생성 가능한 워치페이스가 없습니다.',
    Purchase_Loading: '구매 작업이 진행중 입니다.',
    PurchaseProgress: '구매가 진행중입니다.\n잠시만 기다려주세요',
    PurchaseComplete: '구매가 완료되었습니다.',
    PurchaseFailed: '구매에 실패하였습니다.\n다시 시도해주세요.',
    SellFailed: '판매에 실패하였습니다.\n다시 시도해주세요.',
    Confirm: '확인',
    SignUpCelebrate_FirecrackerIcon: '웰컴 폭죽 이미지',
    Detail_Loading: '상세정보를 불러오고 있습니다...',
    Enter_Email: '이메일을 입력해주세요',
    Enter_Password: '비밀번호를 입력해주세요',
    Enter_NickName: '닉네임을 입력해주세요',
    Enter_VerifyStatement: '인증문구를 입력해주세요',
    Enter_Code: '코드를 입력해주세요',
    EmailResetTerms: '이메일 변경시 재인증이 필요합니다.',
    Check_Email: '해당 이메일이 존재하지 않습니다.',
    Check_Code: '코드가 일치하지 않습니다.',
    Signup_CheckCertification: '인증을 하지 않으셨습니다.',
    Go_Back: '뒤로',
    Signup_Agreeeterms: '약관에 동의하지 않으셨습니다.',
    Signup_Verificationcompleted: '인증완료',
    Signup_CheckVerification: '인증문구를 다시 한번 확인해주세요',
    Signup_CheckPassword: '패스워드가 일치하지 않습니다.',
    Signup_CheckDuplicateEmail: '중복된 이메일입니다.',
    Signup_InvalidNickname: '사용할 수 없는 닉네임입니다.',
    Signup_Signup: '회원가입에 실패하셨습니다 다시 시도 해주세요',
    Signin_Signin: '로그인에 실패하셨습니다 다시 시도 해주세요',
    UserPage_Loading: '사용자 페이지 정보를 불러오고 있습니다...',
    Explore_Loading: '워치페이스 목록을 불러오고 있습니다...',
    Explore_NoSearch: '찾으시는 워치페이스 정보를 검색해주세요.',
    Explore_NoSearchResult: '검색 정보와 일치하는 워치페이스가 없습니다.',
    AgreeTermsModal_TitleText: '이용약관 동의',
    AgreeTermsModal_TitleDetailText: '이용약관 및 개인정보처리방침 동의',
    AgreeTermsModal_AllAgree: '전체 동의',
    AgreeTermsModal_ButtonText: '가입 완료',
    AgreeTermsModal_AgreeUseTerm:
      '타임플릭(TIMEFLIK) 이용약관\n\n제1조(목적)\n\n이 약관은 (주)앱포스터(이하 ‘회사’라 합니다)가 제공하는 모든 서비스와 이와 연계된 모바일 애플리케이션 서비스("타임플',
    AgreeTermsModal_AgreePrivacyTerm:
      '개인정보처리방침\n\n주식회사 앱포스터(이하 “회사”)는 이용자의 개인정보를 보호하고, 이와 관련된 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립하고 공개합니다. ',
    HiddenItem: '신고 처리되어 숨겨진 페이스입니다.',
    ReportReason: '사유',
    ContactUnhide: '숨김 해제를 위해서는 고객센터로 문의해주세요.',
    HiddenContent: '숨김 처리된 컨텐츠 입니다',
    Show_In_App: '앱에서 보기',
    AdjustToWatch: '워치에 적용하기',
    usingTestnet: '테스트넷 사용중입니다.',
    WorkInProcess: '작업 진행중 입니다...\n잠시만 기다려주세요.',
    ConnectWallet_WalletNotConnected: '지갑이 연결되지 않았습니다. 먼저 지갑을 연결해주세요.',
    networkError: '네트워크 오류가 발생했습니다. 잠시 후 다시 시도해주세요.',
    mintingError: 'Something went wrong when updloading the file.',
    chooseDate: 'Choose a date',
    reservationTime: '예약 시간 HH:mm',
    reservationList: '예약 리스트',
    proceedPurchase: '구매를 진행합니다.',
    signingTimeout: '서명 시간이 초과되었습니다. 다시 시도해주세요.',
    approvalAll:
      'nft 토큰에 대한 액세스 권한이 필요합니다.\n 판매를 계속하려면 모든 권한을 승인해주세요.',
    mintNFT: 'NFT 토큰을 생성합니다.',
    proceedSell: '판매를 진행합니다.',
    userCancel: '취소하셨습니다.',
    emailSent: '이메일이 발송되었습니다.',
    emailSentFail: '이메일 발송에 실패했습니다.',
  },
});

export const lang = strings.getLanguage();
