import { useEffect, useState } from 'react';
import { strings } from '../utils/Localization/LocalizationStrings';
import WatchFaceItem from './WatchFaceItem';
import watch from '../assets/img/Group 2517.svg';
import { WatchFaceItem as WatchFaceItemData, WatchFaceNFT } from '../types';
import { getWalletOwner } from '../modules/API/WalletAPI';
import { parseThumbnail } from '../utils/stringUtils';

interface WatchListProps {
  watchItems: (WatchFaceItemData | WatchFaceNFT)[];
  linkFunction: (path: string) => string;
}

interface WatchListItem {
  id: string;
  owner: string;
  title: string;
  thumbnail: string;
  price?: number;
}

export default function WatchList({ watchItems, linkFunction }: WatchListProps) {
  const [itemsWithNickname, setItemWithNickName] = useState<WatchListItem[]>([]);

  useEffect(() => {
    (async () => {
      setItemWithNickName(
        await Promise.all(
          watchItems.map(async (watchItem) => {
            if ('appName' in watchItem) {
              return {
                id: watchItem.appId,
                owner: watchItem.author.nickname,
                title: watchItem.appName,
                thumbnail: parseThumbnail(watchItem.model.preview) ?? '',
              };
            }
            const ownerInfo =
              watchItem.owner != null ? await getWalletOwner(watchItem.owner) : null;
            return {
              id: watchItem._id,
              owner: ownerInfo?.user.nickname ?? watchItem.author.nickname,
              title: watchItem.title,
              thumbnail: parseThumbnail(watchItem.thumbnail) ?? '',
              price: watchItem.price,
            };
          })
        )
      );
    })();
  }, [watchItems]);

  return (
    <section className="flex-grow">
      <header>
        <p className=" text-right text text-[#6C757B] h-[26px] w-full m-auto leading-[26px] my-4">
          {watchItems.length} {strings.WatchCount}
        </p>
      </header>
      <div className="flex flex-row items-start gap-5 m-auto w-full">
        {watchItems.length > 0 ? (
          <ul className="flex flex-wrap flex-1 pb-12">
            {itemsWithNickname.map(({ id, owner, title, thumbnail, price }) => (
              <WatchFaceItem
                url={linkFunction(id)}
                key={id}
                watchOwner={owner}
                watchName={title}
                watchFaceImg={thumbnail}
                watchPrice={price}
              />
            ))}
          </ul>
        ) : (
          <div className="flex flex-col w-full mt-[176px]">
            <img src={watch} alt="시계" className="m-auto " />
            <p className="m-auto text-bold">{strings.SearchNoRsCreate}</p>
          </div>
        )}
      </div>
    </section>
  );
}
