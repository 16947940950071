/* eslint-disable no-alert */
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import logo from '../assets/img/logoWithNft.svg';
import designIcon from '../assets/img/effectIcon.svg';
import downloadIcon from '../assets/img/downloadIcon.svg';
import androidIcon from '../assets/img/androidIcon.svg';
import iosIcon from '../assets/img/iosIcon.svg';
import closeIcon from '../assets/img/closeIcon.svg';
import walletGreen from '../assets/img/walletGreen.svg';
import checkedIcon from '../assets/img/checkIcon_checked.svg';
import backIcon from '../assets/img/icn_back.svg';
import collapseMenu from '../assets/img/collapseMenu.svg';

import walletIcon from '../assets/img/walletIcon.svg';
import { strings } from '../utils/Localization/LocalizationStrings';
import UserinfoContext from '../context/UserinfoContext';
import defaultProfile from '../assets/img/default-profile.png';
import { ellipsisBetween } from '../utils/stringUtils';
import configs from '../constants/configs';
import Modal from '../components/Modal';
import { registerNotification } from '../modules/API/NotificationAPI';
import Notifications from '../containers/Notifications';
import WalletConnectContext from '../context/WalletConnectContext';

function Header({
  searchHeader,
  searchoutHeader,
}: {
  searchHeader?: boolean;
  searchoutHeader?: boolean;
}) {
  const { user } = useContext(UserinfoContext);
  const [menuOpen, setMenuOpen] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const openModal = (message: string) => {
    setModalMessage(message);
    setModalOpen(true);
  };

  const { staticTransactions, requestSigning, connectedWallet } = useContext(WalletConnectContext);

  const history = useHistory();

  useEffect(() => setMenuOpen(false), [history.location]);

  const currentAddress = useMemo(
    () => connectedWallet && ellipsisBetween(connectedWallet, 4),
    [connectedWallet]
  );

  useEffect(() => {
    document.body.style.overflow = menuOpen ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [menuOpen]);

  useEffect(() => {
    if (connectedWallet == null) return () => null;
    const polling = setInterval(async () => {
      try {
        const events = await staticTransactions.listAddressEvents(connectedWallet);
        await registerNotification(
          events.map(({ id, event, returnValues: { tokenId, timestamp } }) => ({
            logId: id,
            event,
            ownerAddress: connectedWallet,
            timestamp: +timestamp,
            tokenId,
          }))
        );
      } catch (err) {
        // do nothing
      }
    }, 5000);
    return () => clearInterval(polling);
  }, [connectedWallet]);

  return (
    <header
      className={`w-full md:mb-[40px] mb-[18px] ${
        searchHeader ? 'md:bg-white' : 'bg-white'
      } md:drop-shadow-[0_20px_20px_rgba(0,0,0,0.1)] drop-shadow-[0_5px_5px_rgba(0,0,0,0.1)] z-[500] relative`}
    >
      <nav className="max-w-[1200px] h-[100px] mx-auto px-3 text-[16px] max-md:hidden md:flex justify-between">
        <div className="justify-between flex-[0_1_875px] flex">
          <div className="flex flex-[0_1_320px] items-center justify-between">
            <Link to={`/${strings.getLanguage()}/`}>
              <img src={logo} alt="TimeFlic nft Logo Icon" />
            </Link>
            <Link to={`/${strings.getLanguage()}/explore`} className="font-semibold">
              {strings.Header_Explore}
            </Link>
            <button
              className="font-semibold"
              onClick={async () => {
                try {
                  if (connectedWallet == null) {
                    openModal(strings.ConnectWallet_WalletNotConnected);
                    return;
                  }
                  await requestSigning('sign for create');
                  history.push(`/${strings.getLanguage()}/facefacelist`);
                } catch (error) {
                  const message = (error as Error).message as keyof typeof strings;
                  openModal((strings[message] as string) ?? strings.ConnectWallet_UnknownError);
                }
              }}
            >
              {strings.Header_Create}
            </button>
          </div>
          <div className="flex flex-[0_1_400px] items-center justify-between">
            <a href="https://studio-dev.timeflik.com/" className="font-semibold">
              <img src={designIcon} alt="Desing Effect Icon" className="inline-block" />
              <span className="ml-[3px]"> {strings.Header_MyDesign}</span>
            </a>

            <a
              href="https://apps.apple.com/us/app/%ED%83%80%EC%9E%84%ED%94%8C%EB%A6%AD-timeflik-%EC%9B%8C%EC%B9%98%ED%8E%98%EC%9D%B4%EC%8A%A4/id1097434319?l=ko"
              className="font-semibold"
            >
              <img src={downloadIcon} alt="Download Icon" className="inline-block" />
              <span className="ml-[3px]"> {strings.Header_MyApp}</span>
            </a>
          </div>
        </div>

        <div className="flex flex-[0_1_280px] items-center justify-between">
          {user != null ? (
            <>
              {currentAddress != null ? (
                <Link
                  to={`/${strings.getLanguage()}/connectwallet`}
                  className="text-[12px] ml-4 text-center w-[111px] py-[7px] px-[5px] font-bold bg-[#E9EAEC] rounded-[4px] text-[#6C757B]"
                >
                  <img
                    src={checkedIcon}
                    className="inline-block h-[15px] mr-[3px]"
                    alt="지갑 아이콘"
                  />
                  <span>{currentAddress}</span>
                </Link>
              ) : (
                <Link
                  to={`/${strings.getLanguage()}/connectwallet`}
                  className="text-[12px] text-center w-[111px] py-[7px] px-[5px] font-bold bg-[#2ACFBB] rounded-[4px] text-white"
                >
                  <img
                    src={walletIcon}
                    className="inline-block h-[15px] mr-[3px]"
                    alt={strings.WalletIcon}
                  />
                  <span> {strings.Header_WalletConnect}</span>
                </Link>
              )}
              <Link to={`/${strings.getLanguage()}/userpage/${user._id}`}>
                <img
                  src={
                    user?.thumbnail != null
                      ? `${configs.REACT_APP_ENDPOINT_MTM}${user.thumbnail}`
                      : defaultProfile
                  }
                  alt="UserImage"
                  className="h-[48px] w-[48px] rounded-full bg-[#D9D9D9]"
                />
              </Link>
              <Notifications address={connectedWallet} />
            </>
          ) : (
            <>
              <div>
                <img src={androidIcon} alt="안드로이드 아이콘" className="mr-5 inline-block" />
                <img src={iosIcon} alt="ios 아이콘" className="inline-block" />
              </div>
              <Link to={`/${strings.getLanguage()}/signin`} className="font-semibold">
                {strings.Header_SingIn}
              </Link>
            </>
          )}
        </div>
      </nav>
      <nav className="md:hidden text-center py-[18px] relative">
        {!searchoutHeader &&
          (searchHeader ? (
            <>
              <button
                onClick={() => history.goBack()}
                className="absolute left-5 top-1/2 -translate-y-1/2"
              >
                <img src={backIcon} alt={strings.GoBackIcon} />
              </button>
              <span className="text-base font-bold">{strings.Search}</span>
              <button
                onClick={() => setMenuOpen(true)}
                type="button"
                className="absolute right-5 top-1/2 -translate-y-1/2"
              >
                <img src={collapseMenu} alt={strings.MenuOpenIcon} />
              </button>
            </>
          ) : (
            <>
              <Link to={`/${strings.getLanguage()}/`} className="inline-block">
                <img src={logo} alt="TimeFlic nft Logo Icon" className="h-5" />
              </Link>
              <button
                onClick={() => setMenuOpen(true)}
                type="button"
                className="absolute right-5 top-1/2 -translate-y-1/2"
              >
                <img src={collapseMenu} alt={strings.MenuOpenIcon} />
              </button>
            </>
          ))}
        {searchoutHeader && (
          <button onClick={() => history.goBack()} className="float-left ml-5">
            <img src={backIcon} alt={strings.GoBackIcon} />
          </button>
        )}

        {/* 모바일 헤더 */}
        {menuOpen && (
          <div className="block md:hidden fixed top-0 left-0 w-screen h-screen bg-white">
            <div className="flex flex-col justify-start h-[calc(100vh-56px)] items-center">
              {/* className=" flex items-center justify-start px-6 w-full" */}
              <div className="flex flex-row w-full justify-between mb-[23px] items-center px-4 py-6">
                <Notifications address={connectedWallet} mobile />
                <button onClick={() => setMenuOpen(false)} type="button">
                  <img src={closeIcon} alt={strings.MenuCloseIcon} />
                </button>
              </div>
              <div className="flex-[0_1_130px]">
                <Link to={`/${strings.getLanguage()}/userpage/${user?._id}`}>
                  {user != null && (
                    <img
                      src={
                        user?.thumbnail != null
                          ? `${configs.REACT_APP_ENDPOINT_MTM}${user.thumbnail}`
                          : defaultProfile
                      }
                      alt="UserImage"
                      className="h-[90px] w-[90px] rounded-full bg-[#D9D9D9]"
                    />
                  )}
                </Link>
              </div>
              <div className="font-semibold flex-[0_1_54px]">
                <Link to={`/${strings.getLanguage()}/explore`}>{strings.Header_Explore}</Link>
              </div>
              <div className="font-semibold flex-[0_1_54px] flex items-start">
                <button
                  onClick={async () => {
                    try {
                      await requestSigning('sign for create');
                      history.push(`/${strings.getLanguage()}/facefacelist`);
                    } catch (error) {
                      const message = (error as Error).message as keyof typeof strings;
                      openModal((strings[message] as string) ?? strings.ConnectWallet_UnknownError);
                    }
                  }}
                >
                  {strings.Header_Create}
                </button>
              </div>
              <div className="font-semibold flex-[0_1_46px]">
                <Link to={`/${strings.getLanguage()}/explore`}>
                  <img src={downloadIcon} alt="Download Icon" className="inline-block" />
                  <span className="ml-[3px]"> {strings.Header_MyApp}</span>
                </Link>
              </div>
              <div className="font-semibold text-[#989898] flex-[0_1_43px]">
                <a
                  href="https://play.google.com/store/apps/details?id=com.apposter.watchmaker"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={androidIcon}
                    className="w-[30px] h-[30px] inline-block mr-[7px]"
                    alt={strings.Header_AndroidDownload}
                  />
                  <span>{strings.Header_AndroidDownload}</span>
                </a>
              </div>
              <div className="font-semibold text-[#989898] flex-[0_1_60px]">
                <a
                  href="https://apps.apple.com/kr/app/%ED%83%80%EC%9E%84%ED%94%8C%EB%A6%AD-timeflik-%EC%9B%8C%EC%B9%98%ED%8E%98%EC%9D%B4%EC%8A%A4/id1097434319"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={iosIcon}
                    className="w-[30px] h-[30px] inline-block mr-[7px]"
                    alt={strings.Header_IosDownload}
                  />
                  <span>{strings.Header_IosDownload}</span>
                </a>
              </div>
              <div className="font-semibold flex-[0_1_69px]">
                <a href="https://studio-dev.timeflik.com/">
                  <img src={designIcon} alt="Desing Effect Icon" className="inline-block" />
                  <span className="ml-[3px]"> {strings.Header_MyDesign}</span>
                </a>
              </div>
              {user != null &&
                (currentAddress == null ? (
                  <div className="text-[14px] text-center font-bold text-[#2ACFBB] flex-[0_1_150px]">
                    <Link to={`/${strings.getLanguage()}/connectwallet`}>
                      <img
                        src={walletGreen}
                        className="inline-block mr-[3px]"
                        alt={strings.WalletIcon}
                      />
                      <span>{strings.Header_WalletConnect}</span>
                    </Link>
                  </div>
                ) : (
                  <div className="flex-[0_1_150px] w-full px-3">
                    <p className="bg-[#E9EAEC] rounded-lg mx-auto max-w-[300px] h-[40px] flex items-center justify-between px-5">
                      <img
                        src={checkedIcon}
                        className="inline-block h-[15px] mr-[3px]"
                        alt="지갑 아이콘"
                      />
                      <span className="text-[#6C757B] text-[13px] font-bold">{currentAddress}</span>
                    </p>
                  </div>
                ))}
              {user == null && (
                <div className="font-semibold text-[#989898] flex-[0_1_114px]">
                  <Link to={`/${strings.getLanguage()}/signin`}>{strings.Header_SingIn}</Link>
                </div>
              )}
              <button
                className="font-semibold text-[#989898]"
                onClick={() => {
                  if (window.Appbridge?.requestGoBackApp != null) {
                    window.Appbridge.requestGoBackApp();
                    return;
                  }
                  window.open(
                    'https://play.google.com/store/apps/details?id=com.apposter.watchmaker'
                  );
                }}
              >
                <span className="text-[#2ACFBB]">{strings.Header_TimeFlicText}</span>
                {strings.Header_BackText}
              </button>
            </div>
          </div>
        )}
      </nav>

      {modalOpen && (
        <Modal
          close={() => setModalOpen(false)}
          outsideClick={() => setModalOpen(false)}
          header={modalMessage}
        />
      )}
    </header>
  );
}

export default Header;
