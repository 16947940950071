import { Chain, EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { configureChains, createClient, goerli, mainnet } from 'wagmi';
import configs from '../constants/configs';

const chains: Chain[] = (configs.REACT_APP_WALLET_CONNECT_CHAINS?.split(',') ?? [])
  .map((chain) => {
    switch (chain) {
      case 'goerli':
        return goerli;
      case 'mainnet':
        return mainnet;
      default:
        return undefined;
    }
  })
  .filter((chain) => chain !== undefined) as Chain[];

export const projectId: string = configs.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? '';

const { provider } = configureChains(chains, [w3mProvider({ projectId })]);

export const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  provider,
});
export const ethereumClient = new EthereumClient(wagmiClient, chains);
